import React from "react";
import "./SocialMedia.css";
import {socialMediaLinks} from "../../portfolio";

export default function socialMedia() {
  if (!socialMediaLinks.display) {
    return null;
  }
  return (
    <div className="social-media-div">
      {socialMediaLinks.twitter ? (
        <a
          href={socialMediaLinks.twitter}
          className="icon-button twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.teg? (
        <a
          href={socialMediaLinks.telam}
          className="icon-button stack-overflow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.new ? (
        <a
          href={`mailto:${socialMediaLinks.neaper}`}
          className="icon-button newser"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-newer"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.newspaper ? (
        <a
          href={socialMediaLinks.newspaper}
          className="icon-button newspaper"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-newspaper"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.facebook ? (
        <a
          href={socialMediaLinks.facebook}
          className="icon-button facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.instagram ? (
        <a
          href={socialMediaLinks.instagram}
          className="icon-button instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.twitter ? (
        <a
          href={socialMediaLinks.twitter}
          className="icon-button twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.medium ? (
        <a
          href={socialMediaLinks.medium}
          className="icon-button medium"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-medium"></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.telegram ? (
        <a
          href={socialMediaLinks.telegram}
          className="icon-button stack-overflow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-telegram"></i>
          <span></span>
        </a>
      ) : null}
    </div>
  );
}
